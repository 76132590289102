import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account-status',
  templateUrl: './account-status.component.html',
  styleUrls: ['./account-status.component.css']
})
export class AccountStatusComponent implements OnInit {
  @ViewChild('statusForm') public any: NgForm;
  accounts: any[] = []; ne: any; set: any; res: any;
  constructor(private api: ApiService, private _use: NavComponent, private _api: ManagerService) { }

  ngOnInit() {
    this.init();
    this.formReset();
    this.getAllAccounts();
  }

  getAllAccounts() {
    this.api.get('accounts/all').subscribe((data: any) => {
      this.accounts = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  getAcct(id) {
    this._api.getAccountbyNumber(id).subscribe((data: any) => {
      this.ne.status = data.status; this.set.accountid = data.accountid;
      this.ne.fullname = data.customer.fullname;
      this.ne.accounttype = data.accounttype.name;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Get Account Details');
    });
  }

  postStatus() {
    this._api.postAccountStatus(this.set).subscribe((data: any) => {
      this.res = data; this.getAcct(this.ne.number); this.formReset();
      this._use.showMsg('success', 'Status Applied', 'Account Status Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Update Fail', 'Unable to Change Account Status');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.set = {
        accountid: '', status: '', userid: this._use.active.Id, date: this._use.date
      };
    }
  }

  init() {
    this.ne = { fullname: '', accounttype: '', status: '', number: null,
      userid: this._use.active.Id, date: new Date(),
    };
  }

}
