import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-company',
  templateUrl: './setup-company.component.html',
  styleUrls: ['./setup-company.component.css']
})
export class SetupCompanyComponent implements OnInit {
  @ViewChild('companyForm') public any: NgForm;
  edit: boolean; list: boolean; com: any; res: any; 
  company: any[]=[]; filtered: any[]=[]; countries: any[]=[]; response: any; imp: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = value;
    this.getMore(1,10);
  }
  constructor(private api: ApiService, private setup: SetupService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getCountry(1,100);
    this.getAllMerchant();
  }

  getAllMerchant() {
    this.api.get("merchant?limit=6").subscribe((data: any) => {
      this.company = data.data; this.filtered = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant Details'); });
  }
  
  getMore(page,limit) {
    this.api.get('merchant?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.company = data.data; this.filtered = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  getCountry(page,limit) {
    this.api.get('country?page='+page+'&limit='+limit).subscribe((data: any) => {
      this.countries = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Country List Fail', 'Unable to Fetch Country List');
    });
  }

  editCom(data) {
    this.com = data;
    this.edit = true; this.list = true;
  }
  delCom(dat) {
    this.setup.delCompany(dat.companyid).subscribe((data: any) => {
      this.res = data; this.getAllMerchant();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Deleting Fail', 'Unable to Delete Merchant'); });
  }

  fileUpload(event) {
    // console.log(event);
    this.setup.Upload(event.target.files[0], 'Logo').subscribe((data: any) => {
      this.com.image = data; 
    });
  }

  postMerchant() {
    this.setup.postCompany(this.com).subscribe((data: any) => {
      this.res = data; this.getAllMerchant();
      this.formReset(); document.getElementById('regis').click();
      this.use.showMsg('success', 'Merchant Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Merchant');
    });
  }

  putMerchant() {
    this.com.muserid = this.use.active.id; this.com.mdate = new Date();
    this.setup.putCompany(this.com.merchantid, this.com).subscribe((data: any) => {
      this.res = data; this.getAllMerchant();
      this.formReset(); document.getElementById('regis').click();
      this.use.showMsg('success', 'Merchant Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.com = {
        merchantid: 0, code: '', type: '', botid: '', charge: 0, expiry: new Date(),
        name: '', email: '', mobile: '', slug: '', logo: 'logo.png', status: 'Active',
        account: '', bank: '', bankname: '', collectorlimit: 100, balance: 0, allow: true,
        allowsms: true, active: true, officer: true, auto: true, reversal: true, agent: true, group: true,
        address: '', countryid: 1, sender: '', send: 0, smscharge: 0,
        userid: this.use.active.id, date: new Date(), muserid: null, mdate: null
      };
      this.listView();
    }
  }
  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

}
