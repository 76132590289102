import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-sms',
  templateUrl: './setup-sms.component.html',
  styleUrls: ['./setup-sms.component.css']
})
export class SetupSmsComponent implements OnInit {
  @ViewChild('smsapiForm') public any: NgForm;
  edit: boolean; list: boolean; sapi: any; smsapi: any[]; merchants: any[]=[];
  res: any; response: any; search: string = '';
  constructor(private api: ApiService, private _setup: SetupService, private use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllApi();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchants();
    }
  }

  getAllApi() {
    this.api.get('smsapi').subscribe((data: any) => {
      this.smsapi = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {  });
  }
  
  getMerchants(page=1,limit=100) {
    this.api.get('merchant?page='+page+'&limit='+limit).subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Employee List'); });
  }
  
  getMore(page,limit) {
    this.api.get('smsapi?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.smsapi = data.data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  editApi(data) {
    this.sapi = this.smsapi.find(s => s.smsapiid === data);
    // this.api = data;
    this.edit = true; this.list = true;
  }
  delApi(dat) {
    this.api.delete('smsapi/'+dat.smsapiid).subscribe((data: any) => {
      this.res = data; this.getMerchants();
    }, (err: HttpErrorResponse) => { });
  }

  saveSmsApi() {
    if(this.sapi.smsapiid > 0) {
      this.putApi();
    } else {
      this.postApi();
    }
  }

  postApi() {
    this.api.post('smsapi',this.sapi).subscribe((data: any) => {
      this.res = data; this.smsapi.push(data);
      this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Api Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Fail', 'Unable to Add New Api');
    });
  }

  putApi() {
    this.sapi.muserid = this.use.active.id; this.sapi.mdate = new Date();
    this.api.put('smsapi/'+this.sapi.smsapiid, this.sapi).subscribe((data: any) => {
      this.res = data; this.getAllApi();
      this.formReset(this.any); this.list = false;
      this.use.showMsg('success', 'Api Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.sapi = { smsapiid: 0, name: '', senderid: '', url: '', callbackurl: '',
      username: '', password: '', default: false, status: 'Active', merchantid: this.use.active.merchantid,
      userid: this.use.active.id, date: new Date(), muserid: null, mdate: null };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }

  back() {
    this.list = false;
  }

}
