import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private api = environment.apiUrl;
  constructor(private http: HttpClient) { }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  getCode(type: string): Observable<any> {
    return this.http.get(this.api + 'sequence/code/' + type);
  }

  getItems(): Observable<any> {
    return this.http.get(this.api + 'item');
  }

  getItem(id: number): Observable<any> {
    return this.http.get(this.api + 'item/' + id);
  }

  postItem(data): Observable<any> {
    return this.http.post(this.api + 'item', JSON.stringify(data));
  }

  putItem(id: number, data): Observable<any> {
    return this.http.put(this.api + 'item/' + id, JSON.stringify(data));
  }

  delItem(id: number): Observable<any> {
    return this.http.delete(this.api + 'item/' + id);
  }

  getStocks(): Observable<any> {
    return this.http.get(this.api + 'stock');
  }

  getStock(id: number): Observable<any> {
    return this.http.get(this.api + 'stock/' + id);
  }

  getStockLog(id: number): Observable<any> {
    return this.http.get(this.api + 'stock/log/' + id);
  }

  getStockLogs(): Observable<any> {
    return this.http.get(this.api + 'stock/log');
  }

  postStock(data): Observable<any> {
    return this.http.post(this.api + 'stock', JSON.stringify(data));
  }

  putStock(id: number, data): Observable<any> {
    return this.http.put(this.api + 'stock/' + id, JSON.stringify(data));
  }

  delStock(id: number): Observable<any> {
    return this.http.delete(this.api + 'stock/' + id);
  }

  getSales(): Observable<any> {
    return this.http.get(this.api + 'sales');
  }

  getSale(id: number): Observable<any> {
    return this.http.get(this.api + 'sales/' + id);
  }

  getSaleLog(id: number): Observable<any> {
    return this.http.get(this.api + 'sales/item/' + id);
  }

  postSale(data): Observable<any> {
    return this.http.post(this.api + 'sales', JSON.stringify(data));
  }

  putSale(id: number, data): Observable<any> {
    return this.http.put(this.api + 'sales/' + id, JSON.stringify(data));
  }

  delSale(id: number): Observable<any> {
    return this.http.delete(this.api + 'sales/' + id);
  }

}
