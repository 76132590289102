import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.sass']
})
export class LogsComponent implements OnInit {
  payments: any = { totalCount: 0 }; filtered: any[] = []; merchants: any[] = []; 
  rpt: any; id: number; exp: any[]; balance = 0; total = 0; topup: any; edit = false; saving: boolean = false; view: boolean = false;
  types: any[] = [ "TITHE", "OFFERING", "DONATION", "RECEIVE", "SEND" ]; tran: any; 

  constructor(private api: ApiService, private set: SetupService, private ext: ExportExcelService, public use: NavComponent) { }

  ngOnInit() {
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, page: 1, per_page: 12, source: "fsp", keyword: "", code: null, date: new Date() };
    this.topup = { amount: 0.1, botid: "Bluespace", charge: 0, code: 2, date: "2020-09-03T09:31:45.684962", merchantid: 35, mobile: "+233550079986", network: "MTN", reference: "test d4006ac3_2", response: "FAILED - Insufficient Funds", service: "Payment", settled: false, source: "Integration", tranid: "PA75M3401", transaction_no: "1018-1599118303", type: "RECEIVE", userid: "Bright Ahiadeke"};
    this.getAllPayment();
    this.getMerchants();
  }

  getMerchants() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getMerchant(val) {
    this.api.get('Merchant' + val).subscribe((data: any) => {
      this.balance = data.balance;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant Balance');
    });
  }

  getAllPayment() {
    const q = this.rpt; this.view = false;
    this.api.getlog('Payments/All?merchant=fsp&page='+q.page+'&per_page='+q.per_page+'&source='+q.source+'&keyword='+q.keyword+'&code='+q.code).subscribe((data: any) => {
      this.payments = data.result; this.filtered = data.result.items; this.getTotal(data.result.items);
      setTimeout(() => {
        $('.footable').footable();
      }, 2000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  getPayments() {
    const q = this.rpt; this.view = false;
    this.api.getlog('Payments/All?merchant=fsp&page='+q.page+'&per_page='+q.per_page+'&source='+q.source+'&keyword='+q.keyword+'&code='+q.code).subscribe((data: any) => {
      this.payments = data.result; this.filtered = data.result.items; 
      this.getTotal(data.result.items);
        // $.getScript('assets/js/plugins/footable/footable.all.min.js');
      setTimeout(() => {
        // $('.footable').footable();
        $('table').trigger('footable_redraw');
      }, 2000);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  getNextPage() {
    this.rpt.page +=1;
    this.getPayments()
  }
  getPreviousPage() {
    this.rpt.page -=1;
    this.getPayments()
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  formReset(val) {
    let scode = val.status_Code;
    if(val.status_Code == -1) { scode = 2}
    this.topup = { amount: val.amount, botid: val.merchant, charge: val.charge, code: scode, date: val.creationTime, merchantid: '', mobile: val.mobile, network: val.mobile_network, reference: val.order_desc, response: val.status_Message, service: '', settled: false, source: '', tranid: val.order_id, transaction_no: val.trans_Ref_No, transaction_ref: '', type: val.type, userid: this.use.active.id }
    this.saving = false; this.view = false;
  }

  postTopup() {
    this.saving = true;
    this.api.post('Payments',this.topup).subscribe((data: any) => {
      this.use.showMsg('success', 'Posted', 'Transaction Posted To Merchant Portal');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Posting Fail', err.message);
      this.saving = false;
    });
  }

  checkPayment(val) {
    const code = val.trans_Ref_No;
    if(!code || code == 0) { code == val.order_id }
    this.api.getlog('Payments/GetCallback?Orderid='+ code).subscribe((data: any) => {
      document.getElementById('close').click();
      if(data.result.status_code == 1) {
        // this.getPayments();
        val.status_Code = data.result.status_code;
        val.status_Message = data.result.status_message;
        this.use.showMsg('success', 'Confirmed', 'Transaction Confirm sucessfully');
      } else {
        val.status_Code = data.result.status_code;
        val.status_Message = data.result.status_message;
        this.use.showMsg('success', 'Still Pending', 'Transaction Status is Still Pending');
      }
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Checking Fail', 'Unable to Check Payment Status');
    });
  }

  postCallback(value) {
    const data = { status_code: value.status_Code, status_message: value.status_Message, transaction_no: value.trans_Ref_No, interpaytxref: value.order_id }
    this.api.post('Callback?orderid='+value.trans_Ref_No || value.order_id, data).subscribe((data: any) => {
      this.use.showMsg('success', 'Sent Callback', 'Sent Callback successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Callback Fail', 'Unable to Sent Merchant Callback');
    });
  }

  getTotal(trans) {
    
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.status_Code === 1) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  export() {
    const q = this.rpt;
    this.api.getlog('Payments/GetAll?merchant=fsp&page='+q.page+'&per_page='+q.per_page+'&source='+q.source+'&keyword='+q.keyword+'&code='+q.code).subscribe((data: any) => {
      if (data.result.items.length > 0) {
        this.ext.exportAsExcelFile(data.result.items, 'payments_logs');
        this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
      } else {
        this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
      }
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
    
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.tranid, TYPE: e.type, MOBILE: e.mobile, SERVICE: e.service,
        AMOUNT: e.amount, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
