import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = environment.apiUrl; 
  apis = environment.apiUrls; 
  apilog = environment.api; 
  public imgApi = environment.apiUrl;
  private isLoading = new Subject<boolean>();
  public Loading = this.isLoading.asObservable();
  user: any;

  constructor(private http: HttpClient) { }

  show() {
    this.isLoading.next(true);
    // this.Loading = true;
  }

  hide() {
    this.isLoading.next(false);
    // this.Loading = false;
  }

  getstatus(route): Observable<any> {
    // this.show();
    return this.http.get(this.apis + route);
  }

  getlog(route): Observable<any> {
    // this.show();
    return this.http.get(this.apilog + route);
  }

  postcall(route,data): Observable<any> {
    // this.show();
    return this.http.post(this.apilog + route, data);
  }

  sendrequest(route, data): Observable<any> {
    // this.show();
    return this.http.post(route, data);
  }

  getdata(route): Observable<any> {
    // this.show();
    return this.http.get(route);
  }

  getBalance(route): Observable<any> {
    // this.show();
    return this.http.get(this.apis + route);
  }

  postdata(rout, data): Observable<any> {
    // this.show();
    return this.http.post(rout, JSON.stringify(data));
  }
  
  get(route): Observable<any> {
    // this.show();
    return this.http.get(this.api + route);
  }

  post(rout, data): Observable<any> {
    // this.show();
    return this.http.post(this.api + rout, JSON.stringify(data));
  }

  put(rout, data): Observable<any> {
    // this.show();
    return this.http.put(this.api + rout, JSON.stringify(data));
  }

  delete(rout): Observable<any> {
    // this.show();
    return this.http.delete(this.api + rout);
  }

}