import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TellerService {
  private api = environment.apiUrl;
  constructor(private http: HttpClient) { }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  getCode(type: string): Observable<any> {
    return this.http.get(this.api + 'sequence/code/' + type);
  }

  postConfirmSms(data): Observable<any> {
    return this.http.post(this.api + 'sms/confirm', JSON.stringify(data));
  }

  getDashboard(id: string): Observable<any> {
    return this.http.get(this.api + 'dashboard/' + id);
  }

  getEmployees(): Observable<any> {
    return this.http.get(this.api + 'employee');
  }

  getEmployee(id: number): Observable<any> {
    return this.http.get(this.api + 'employee/' + id);
  }

  postEmployee(data): Observable<any> {
    return this.http.post(this.api + 'employee', JSON.stringify(data));
  }

  putEmployee(id: number, data): Observable<any> {
    return this.http.put(this.api + 'employee/' + id, JSON.stringify(data));
  }

  delEmployee(id: number): Observable<any> {
    return this.http.delete(this.api + 'employee/' + id);
  }

  getTellers(): Observable<any> {
    return this.http.get(this.api + 'teller');
  }

  getTeller(id: number): Observable<any> {
    return this.http.get(this.api + 'teller/' + id);
  }

  getTellerSummary(id: string, date: Date): Observable<any> {
    return this.http.get(this.api + 'teller/summary/' + id + '/' + date);
  }

  getTellerBalance(id: number): Observable<any> {
    return this.http.get(this.api + 'teller/balance/' + id);
  }

  getNominalBalance(id: number): Observable<any> {
    return this.http.get(this.api + 'nominal/balance/' + id);
  }

  postTeller(data): Observable<any> {
    return this.http.post(this.api + 'teller', JSON.stringify(data));
  }

  putTeller(id: number, data): Observable<any> {
    return this.http.put(this.api + 'teller/' + id, JSON.stringify(data));
  }

  delTeller(id: number): Observable<any> {
    return this.http.delete(this.api + 'teller/' + id);
  }

  postTransfer(data): Observable<any> {
    return this.http.post(this.api + 'teller/transfer', JSON.stringify(data));
  }

  postNominalTransfer(data): Observable<any> {
    return this.http.post(this.api + 'nominal/transfer', JSON.stringify(data));
  }

  postCashTransfer(data): Observable<any> {
    return this.http.post(this.api + 'nominal/disburment', JSON.stringify(data));
  }

  postVoucher(data): Observable<any> {
    return this.http.post(this.api + 'teller/voucher', JSON.stringify(data));
  }

  postPayment(data): Observable<any> {
    return this.http.post(this.api + 'nominal/payment', JSON.stringify(data));
  }

  Receipt(data): Observable<any> {
    return this.http.post(this.api + 'process/receipt/', JSON.stringify(data));
  }

  generateTrialBalance(data): Observable<any> {
    return this.http.post(this.api + 'report', JSON.stringify(data));
  }

  Enquiry(data): Observable<any> {
    return this.http.post(this.api + 'enquiry', JSON.stringify(data));
  }

  IncomeReport(data): Observable<any> {
    return this.http.post(this.api + 'income', JSON.stringify(data));
  }

  getCashbook(): Observable<any> {
    return this.http.get(this.api + 'cashbook');
  }

  getDaybook(data: any): Observable<any> {
    return this.http.post(this.api + 'daybook', JSON.stringify(data));
  }

  getSummarys(date: string): Observable<any> {
    return this.http.get(this.api + 'summary/' + date);
  }

  getSummary(): Observable<any> {
    return this.http.get(this.api + 'summary');
  }

  getTransactions(): Observable<any> {
    return this.http.get(this.api + 'transaction');
  }

  getTransaction(id: number): Observable<any> {
    return this.http.get(this.api + 'transaction/' + id);
  }

  postTransaction(data): Observable<any> {
    return this.http.post(this.api + 'transaction', JSON.stringify(data));
  }

  putTransaction(id: number, data): Observable<any> {
    return this.http.put(this.api + 'transaction/' + id, JSON.stringify(data));
  }

  delTransaction(id: number): Observable<any> {
    return this.http.delete(this.api + 'transaction/' + id);
  }

}
