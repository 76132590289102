import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SigninService } from 'src/app/services/signin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  log: any = {}; isLoginError: boolean; res: any; returnUrl: string; is2FA:boolean = false; log2FA:any = {}; isOtpLoginError:boolean;
  constructor(private _api: SigninService, private router: Router, private route: ActivatedRoute, private _msg: ToastrService) { }

  ngOnInit() {
    this.resetLogin();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    const message = this.route.snapshot.queryParams['message'];
    // console.log(this.returnUrl);
  }

  login() {
    this._api.loginUser(this.log).subscribe((data: any) => {
      if(data.access_token == null)
      {
        this.is2FA = true;
      }
      else{
        localStorage.setItem('Token', JSON.stringify(data.access_token));
        localStorage.setItem('Date', JSON.stringify(data.date));
        // this.router.navigate([this.returnUrl]); location.reload(true);
        window.location.href = this.returnUrl;
      }
    }, (err: HttpErrorResponse) => { 
      this.isLoginError = true;
      this.res = 'Incorrect Username or Password'; });
  }

  login2FA() {
    this._api.loginUser2FA(this.log2FA).subscribe((data: any) => {
      localStorage.setItem('Token', JSON.stringify(data.access_token));
      localStorage.setItem('Date', JSON.stringify(data.date));
      // this.router.navigate([this.returnUrl]); location.reload(true);
      window.location.href = this.returnUrl;
    }, (err: HttpErrorResponse) => { 
      this.isOtpLoginError = true;
      this.res = 'Incorrect Username or Password'; });
  }

  resetLogin(form?: NgForm) {
    if (form != null) { form.reset(); }
    this.log = { username: '', password: '' };
  }

  loginBack(){
    this.is2FA = false;
  }

  otpLogin(){
    this.is2FA = true;
  }
}
