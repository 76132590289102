import { Component, OnInit } from '@angular/core';
import { SigninService } from 'src/app/services/signin.service';

@Component({
  selector: 'app-foot',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.css']
})
export class FootComponent implements OnInit {
  active: any; date: string;
  constructor(private ser: SigninService) { }

  ngOnInit() {
    this.active = this.ser.userDetails();
    this.date = JSON.parse(localStorage.getItem('Date'));
  }

}
