import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-add-agent',
  templateUrl: './add-agent.component.html',
  styleUrls: ['./add-agent.component.css']
})
export class AddAgentComponent implements OnInit {
  @ViewChild('agentForm') public any: NgForm;
  off: any; edit: Boolean; res: any; id: any; accounts: any[] = [];
  loc: any[] = []; cust: any[] = []; users: any[] = []; user: any; merchants: any[] = [];
  constructor(private api: ApiService, private _nav: Router, private _route: ActivatedRoute, private use: NavComponent) { }

  ngOnInit() {
    this.user = this.use.active;
    this.formReset();
    this.getAll();
    if(this.use.active.typ ==="Admin" || this.use.active.typ === "Support") {
      this.getMerchant();
    }
  }

  getAll() {
    this.id = this._route.snapshot.paramMap.get('id');
    this.getGrp(this.id);
    this.api.get('location/all').subscribe((data: any) => {
      this.loc = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Location List Fail', 'Unable to Fetch Location List');
    });
  }

  getMerchant() {
    this.api.get('merchant?limit=100').subscribe((data: any) => {
      this.merchants = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getGrp(id: any) {
    if (id && id !== 0) {
      this.edit = true;
      this.api.get('agent/'+id).subscribe((data: any) => {
        this.off = data; this.accounts = data.account; // this.Selected();
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Get Agent Details'); });
    } else {
      this.edit = false;
      this.formReset();
    }
  }

  saveAgent() {
    if(this.off.agentid > 0) {
      this.putAgent();
    } else {
      this.postAgent();
    }
  }

  postAgent() {
    this.api.post('agent',this.off).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Agent Created', 'Added to list Successfully');
      this._nav.navigate(['/agents']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Creating Fail', 'Unable to Add New Agent');
    });
  }

  putAgent() {
    this.off.muserid = this.use.active.id; this.off.mdate = new Date();
    this.api.put('agent/'+this.off.agentid, this.off).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this.use.showMsg('success', 'Agent Updated', 'Agent Details Updated Successfully');
      this._nav.navigate(['/agents']);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Updating Fail', 'Unable to Update Agent Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.off = {
        agentid: 0, code: null, name: '', email: '', mobile: '', pin: '1234', percentage: 0, locationid: '',
        active: true, status: 'Active', merchantid: this.use.active.merchantid,
        userid: this.use.active.id, date: this.use.date, muserid: null, mdate: null
      };
    }
  }

}
